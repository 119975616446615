<template>
  <div>
    <header class="column no-wrap">
      <Header
        class="grow"
        @update-patient-details="updatePatientDetails"
        @record-event="recordEvent"
      />
      <div
        v-if="cprStarted"
        class="row status no-wrap items-center"
      >
        <div class="margin-left-auto">
          CPR Duration: {{ cprDuration }}
        </div>
        <div class="margin-x-lg">Cycle: {{ cycleNumber }}</div>
        <button
          v-if="!['finished', 'summary'].includes(stage)"
          class="stop-button"
          @click="ceaseCPR"
        >
          Stop
        </button>
      </div>
    </header>
    <main class="column justify-center items-center no-wrap">
      <div class="relative full-width">
        <EventNotification :event="events.slice(-1)[0]" />
      </div>
      <div
        v-if="events.length && !['finished', 'summary'].includes(stage)"
        class="latest-event justify-center"
      >
        <EventRow :event="events.slice(-1)[0]" />
      </div>
      <div
        v-html="stageTitle"
        class="stage-title full-width"
      />
      <div class="row grow justify-center items-center full-width">
        <Workflow
          :stage="stage"
          :time-remaining="timeRemaining"
          :events="events"
          :staff-details="staffDetails"
          :patient-details="patientDetails"
          class="row justify-center items-center options full-width"
          @state-change="handleStateChange"
          @record-event="recordEvent"
        />
      </div>
    </main>
    <footer>
      <Footer
        :patient-details="patientDetails"
        @record-event="recordEvent"
        @update-staff-details="updateStaffDetails"
      />
    </footer>
  </div>
</template>

<script>
import EventNotification from '@/components/EventNotification';
import EventRow from '@/components/EventRow';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import Workflow from '@/components/Workflow';
import playTick from '@/services/metronomeService';

export default {
  name: 'App',
  components: {
    EventNotification,
    EventRow,
    Footer,
    Header,
    Workflow,
  },
  data() {
    return {
      timeRemaining: 0,
      timerStarted: false,
      startTimestamp: 0,
      cycleNumber: 0,
      stage: 'Start CPR',
      cadence: 0,
      cprStarted: false,
      cprEnded: false,
      clockId: null,
      compressionTimeout: null,
      cprStartedAt: 0,
      cprDuration: '0:00',
      patientDetails: {
        patientName: '',
        patientWeight: 0,
      },
      staffDetails: {
        vetInitials: '',
        nurseInitials: '',
      },
      events: [],
    };
  },
  computed: {
    stageTitle() {
      switch(this.stage) {
        case 'evaluate':
          return 'Evaluate';
        case 'summary':
        case 'finished':
          return 'Summary';
        case 'nonShockable':
          return 'Non-shockable Rythm';
        case 'shockable':
          return 'Shockable Rythm';
        case 'defibrillate':
          return 'Defibrillate';
        case 'cpr-ceased':
          return 'CPR Ceased';
        case 'compressions':
          return `Perform Compressions<br>@ ${this.cadence} CPM`
        default:
          return this.stage;
      }
    },
  },
  watch: {
    cprStarted() {
      this.cprStartedAt = Date.now();
    },
  },
  mounted() {
    this.clockId = setInterval(this.clock, 250);
  },
  beforeUnmount() {
    clearInterval(this.clockId);
    clearTimeout(this.compressionTimeout);
  },
  methods: {
    updatePatientDetails(newDetails) {
      this.patientDetails = { ...this.patientDetails, ...newDetails };
    },
    updateStaffDetails(newDetails) {
      this.staffDetails = { ...this.staffDetails, ...newDetails };
    },
    recordEvent({ event, details }) {
      const newEvent = {
        timestamp: Date.now(),
        event,
        details,
      }

      this.events.push(newEvent);
    },
    clock() {
      this.updateCprDuration();
    },
    updateCprDuration() {
      if (!this.cprStarted || this.cprEnded) return;

      let minutes, seconds;

      const duration = (Date.now() - this.cprStartedAt) / 1000;

      seconds = Math.floor(duration % 60).toString();
      minutes = Math.floor(duration / 60).toString();

      this.cprDuration = `${minutes}:${seconds.length > 1 ? seconds : `0${seconds}`}`;
    },
    handleStateChange(stage, ...params) {
      switch (stage) {
        case 'nonShockable':
          this.nonShockable();
          break;
        case 'shockable':
          this.shockable();
          break;
        case 'rosc':
          this.rosc();
          this.recordEvent({ event: 'return-of-spontaneous-circulation' });
          break;
        case 'compressions':
          this.cprStarted = true;
          this.startCompressions(...params);
          this.recordEvent({ event: 'compressions-started' });
          break;
        case 'finish':
          this.stage = 'finished';
          this.recordEvent({ event: 'cpr-ceased-reason', details: params[0] });
          break;
        default:
          break;
      }
    },
    compressionCadence() {
      const interval = 60 / this.cadence;
      if (this.timerStarted) {
        this.compressionTimeout = setTimeout(() => {
          this.compressionCadence()
        }, interval * 1000);
      }

      playTick();
    },
    startCompressions(cadence = this.cadence) {
      this.cadence = cadence;
      this.stage = 'compressions';
      this.timerStarted = true;
      this.compressionCadence();
      this.refreshTimer();
      this.cycleNumber += 1;
    },
    refreshTimer(timestamp) {
      if (!this.timerStarted) return;

      let delta = 0;
      if (timestamp === undefined) {
        this.startTimestamp = window.performance.now();
      } else {
        delta = timestamp - this.startTimestamp;
      }

      this.timeRemaining = parseInt(process.env.VUE_APP_CYCLE_DURATION) - (delta / 1000);

      if (this.timeRemaining > 0) {
        window.requestAnimationFrame(this.refreshTimer);
      } else {
        this.timeRemaining = 0;
        this.endCycle();
      }
    },
    endCycle() {
      this.timerStarted = false;
      this.stage = 'evaluate';
      this.recordEvent({ event: 'compressions-halted' });
    },
    rosc() {
      this.stage = 'summary';
      this.cprEnded = true;
    },
    nonShockable() {
      this.stage = 'nonShockable';
    },
    shockable() {
      this.stage = 'shockable';
    },
    ceaseCPR() {
      this.stage = 'cpr-ceased'
      this.cprEnded = true;
      this.timerStarted = false;
      this.recordEvent({ event: 'cpr-ceased' });
    }
  },
};
</script>

<style scroped>
  header, footer {
    height: 100px;
    background-color: var(--color-cpr-blue);
    padding: 6px;
  }

  main {
    height: calc(100vh - 200px);
    background-color: var(--color-cpr-gray);
  }

  .stop-button {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .stage-title  {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
  }

  .status {
    box-sizing: content-box;
    font-size: 1.2rem;
    color: white;
    font-weight: 500;
  }

  .latest-event {
    background-color: white;
    width: 100%;
    font-size: .8rem;
  }

</style>
