<template>
  <div>
    <template v-if="stage === 'compressions'">
      <div class="countdown">
        {{ formattedTimeRemaining }}
      </div>
    </template>
    <template v-else-if="stage === 'cpr-ceased'">
      <button
        class="cpr-button"
        @click.stop="$emit('state-change', 'finish', 'Clinician Decision')"
      >
        Clinician Decision
      </button>
      <button
        class="cpr-button"
        @click.stop="$emit('state-change', 'finish', 'Client Decision')"
      >
        Client Decision
      </button>
      <button
        class="cpr-button"
        @click.stop="$emit('state-change', 'finish', 'Futile')"
      >
        Futile
      </button>
    </template>
    <template v-else-if="stage === 'evaluate'">
      <button
        class="cpr-button"
        @click.stop="$emit('state-change', 'nonShockable')"
      >
        Asystole / PEA
      </button>
      <button
        class="cpr-button"
        @click.stop="$emit('state-change', 'shockable')"
      >
        VF / Pulseless VT
      </button>
      <button
        class="cpr-button"
        @click="$emit('state-change', 'rosc')"
      >
        ROSC
      </button>
    </template>
    <template v-else-if="stage === 'nonShockable'">
      <button
        class="cpr-button"
        @click="$emit('state-change', 'compressions')"
      >
        Resume Compressions
      </button>
    </template>
    <template v-else-if="stage === 'shockable'">
      <button
        class="cpr-button"
        @click="shock"
      >
        Shock<br>
        <span v-if="shockEnergy">
          {{ shockEnergy }}
        </span>
        <span :class="shockEnergy ? 'font-weight-normal font-smaller' : ''">
          @ 2-4J/kg
        </span>
      </button>
       <button
        class="cpr-button"
        @click="thump"
      >
        Precordial Thump
      </button>
      <button
        class="cpr-button"
        @click="$emit('state-change', 'compressions')"
      >
        Resume Compressions
      </button>
    </template>
    <template v-else-if="['summary', 'finished'].includes(stage)">
      <Summary
        :events="events"
        :staff-details="staffDetails"
        :patient-details="patientDetails"
      />
    </template>
    <template v-else>
      <button
        class="cpr-button"
        @click="$emit('state-change', 'compressions', CADENCE_SMALL_DOG)"
      >
        Small Dog/Cat
      </button>
      <button
        class="cpr-button"
        @click="$emit('state-change', 'compressions', CADENCE_LARGE_DOG)"
      >
        Large Dog
      </button>
    </template>
  </div>
</template>

<script>
import Summary from '@/components/Summary';

const CADENCE_SMALL_DOG = 120; // beats per minute
const CADENCE_LARGE_DOG = 100; // beats per minute

export default {
  name: 'Workflow',
  components: {
    Summary,
  },
  props: {
    stage: {
      type: String,
      required: true,
    },
    timeRemaining: {
      type: Number,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    patientDetails: {
      type: Object,
      required: true,
    },
    staffDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      CADENCE_SMALL_DOG,
      CADENCE_LARGE_DOG,
    };
  },
  computed: {
    formattedTimeRemaining() {
      let minutes, seconds;

      seconds = Math.floor(this.timeRemaining % 60).toString();
      minutes = Math.floor(this.timeRemaining / 60).toString();

      return `${minutes}:${seconds.length > 1 ? seconds : `0${seconds}`}`;
    },
    shockEnergy() {
      if (this.patientDetails.patientWeight) {
        const min = this.patientDetails.patientWeight * 2;
        const max = min * 2;

        return `${min} - ${max}J`;
      }

      return '';
    }
  },
  methods: {
    shock() {
      this.$emit('record-event', { event: 'shock-administered' });
    },
    thump() {
      this.$emit('record-event', { event: 'thump-administered' });
    },
  }
};
</script>

<style scoped>
  .cpr-button {
    width: 250px;
    min-height: 100px;
    height:auto;
    background-color:var(--color-cpr-blue);
    font-size: 2rem;
    font-weight: 500;
    margin: 8px;
    border-radius: 3px;
  }

  .countdown {
    font-size: 120px;
  }
</style>
