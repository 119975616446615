<template>
  <div class="column col-shrink full-width">
    <div class="column summary col-shrink no-wrap">
      <div class="row patient-details">
        <div class="detail">Patient Name: {{ patientDetails.patientName || 'Not supplied' }}</div>
        <div class="detail">Weight: {{ `${patientDetails.patientWeight}kg` || 'Not supplied' }}</div>
      </div>
      <div class="row staff-details">
        <div
          v-if="staffDetails.vetInitials"
          class="detail"
        >
          Vet: {{ staffDetails.vetInitials }}
        </div>
        <div
          v-if="staffDetails.nurseInitials"
          class="detail"
        >
          Nurse: {{ staffDetails.nurseInitials }}
        </div>
      </div>
      <div class="column events no-wrap col-shrink scroll">
        <EventRow
          v-for="event in events"
          :key="event.timestamp"
          :event="event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventRow from "./EventRow";

export default {
  name: 'Summary',
  components: {
    EventRow,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    patientDetails: {
      type: Object,
      required: true,
    },
    staffDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
  .summary {
    font-size: .75rem;
    background-color: white;
    border-radius: 3px;
    margin: 6px;
    padding: 6px;
  }

  .summary .row {
    padding: 2px 4px;
  }

  .detail {
    margin-left: 8px;
  }

  .detail:first-child {
    margin-left: 0;
  }

</style>
