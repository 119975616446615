<template>
  <div>
    <button
      class="icon-button"
      @click="togglePopup"
    >
      <span class="material-icons">vaccines</span>
    </button>
    <!-- Below is fixed and doesn't affect layout -->
    <div
      v-if="popupVisible"
      class="popup column justify-center"
      @click="togglePopup"
    >
      <div class="drugs-container">
        <div
          v-if="!patientWeight"
          class="text-center text-warning margin-y-sm"
        >
          Please enter patient weight for pre-calculated doses
        </div>
        <div class="text-center margin-y-sm">
          Tap drug to record administration
        </div>
        <div
          class="row justify-center"
          @click.stop
        >
          <div
            v-for="(drugList, category) in drugs"
            :key="category"
            class="column drug-column"
          >
            <button
              v-for="drug in drugList"
              :key="drug.label"
              class="drug-button"
              :class="category.toLowerCase()"
              @click="recordAdministration(drug)"
            >
              <span class="drug-label">{{ drug.label }}</span><br>
              <span class="drug-concentration">({{ concentrationLabel(drug) }})</span><br>
              <template v-if="patientWeight">
                <span class="drug-dose">
                  {{ calculatedDose(drug) }} {{ drug.suspensionUnit }}<br>
                </span>
                <span class="font-weight-normal">
                  @ {{ drug.dose }} {{ drug.doseUnit }}/kg
                </span>
              </template>
              <span
                v-else
                class="drug-dose"
              >
                {{ drug.dose }} {{ drug.doseUnit }}/kg
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DrugsPopup',
  props: {
    patientWeight: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      popupVisible: false,
      drugs: {
        arrest: [
          {
            label: 'Epi (low)',
            concentration: 1,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 0.01,
            doseUnit: 'mg',
          },
          {
            label: 'Epi (high)',
            concentration: 1,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 0.1,
            doseUnit: 'mg',
          },
          {
            label: 'Vasopressin',
            concentration: 20,
            concentrationUnit: 'U',
            suspensionUnit: 'ml',
            dose: 0.8,
            doseUnit: 'U',
          },
          {
            label: 'Atropine',
            concentration: 0.65,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 0.04,
            doseUnit: 'mg',
          },
        ],
        'Anti-Arrythmia': [
          {
            label: 'Amiodarone',
            concentration: 50,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 5,
            doseUnit: 'mg',
          },
          {
            label: 'Lignocaine',
            concentration: 20,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 2,
            doseUnit: 'mg',
          },
        ],
        Reversal: [
          {
            label: 'Naloxone',
            concentration: 0.4,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 0.04,
            doseUnit: 'mg',
          },
          {
            label: 'Flumazenil',
            concentration: 0.1,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 0.01,
            doseUnit: 'mg',
          },
          {
            label: 'Atipamezole',
            concentration: 5,
            concentrationUnit: 'mg',
            suspensionUnit: 'ml',
            dose: 0.1,
            doseUnit: 'mg',
          },
        ],
      },
    };
  },
  methods: {
    concentrationLabel(drug) {
      return drug.concentration + ' ' + drug.concentrationUnit + '/' + drug.suspensionUnit;
    },
    calculatedDose(drug) {
      return (drug.dose * this.patientWeight / drug.concentration).toFixed(2);
    },
    togglePopup() {
      this.popupVisible = !this.popupVisible;
    },
    recordAdministration(drug) {
      let dose = `@ ${drug.dose} ${drug.doseUnit}/kg`;
      if (this.patientWeight) {
        dose = `${this.calculatedDose(drug)} ${drug.suspensionUnit}`;
      }
      this.$emit('record-event', {
        event: 'drug-administered',
        details: `${drug.label} - ${dose}`,
      });
    }
  }
};
</script>

<style scoped>
  .drug-column {
    max-width: 30%;
    margin: 0 4px;
  }
  .drug-button {
    border-radius: 3px;
    color: black;
    padding: 4px;
    margin: 4px;
    height:auto;
    width: 100%;
    line-height: 1rem;
  }

  .drug-label {
    font-weight: 600;
  }

  .drug-concentration {
    font-size: 0.75rem;
  }

  .drug-dose {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  .drug-button.anti-arrythmia {
    background-color: plum;
  }
  .drug-button.arrest {
    background-color: cadetblue;
  }
  .drug-button.reversal {
    background-color: greenyellow;
  }

  .popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .drugs-container {
    background: white;
    border-radius: 6px;
    margin: 8px;
    padding: 6px;
  }

  .icon-button {
    background: white;
    height: 2.5em;
    border-radius: 3px;
    padding: 3px;
    color: var(--color-cpr-purple);
  }
</style>
