// for cross browser compatibility
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioCtx = new AudioContext();
const mainGainNode = audioCtx.createGain();

mainGainNode.connect(audioCtx.destination);
mainGainNode.gain.value = parseInt(process.env.VUE_APP_METRONOME_VOLUME);

const buf = audioCtx.createBuffer(1, audioCtx.sampleRate * 2, audioCtx.sampleRate);
const channel = buf.getChannelData(0);

let phase = 0;
let amp = 1;
const duration_frames = audioCtx.sampleRate / 50;
const f = 330;

for (let i = 0; i < duration_frames; i++) {
  channel[i] = Math.sin(phase) * amp;
  phase += 2 * Math.PI * f / audioCtx.sampleRate;
  if (phase > 2 * Math.PI) {
    phase -= 2 * Math.PI;
  }
  amp -= 1 / duration_frames;
}

export default function playTick(duration = 100) {
  const source = audioCtx.createBufferSource();
  source.buffer = buf;
  source.connect(mainGainNode);
  source.start(0);
  setTimeout(() => source.stop(), duration);
}
