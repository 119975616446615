<template>
  <div
    ref="notificationContainer"
    class="absolute event-notification-container"
  >
    <div
      v-if="event"
      class="notification-content"
    >
      {{ eventLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventNotification',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    eventLabel() {
      return this.event.event.split('-').map(l => l[0].toUpperCase() + l.substring(1) ).join(' ');
    }
  },
  watch: {
    event: {
      immediate: true,
      handler: 'showNotification',
    },
  },
  methods: {
    showNotification() {
      if (!this.event) return;
      console.log(this.$refs.notificationContainer);
      if (!this.$refs.notificationContainer) {
        console.log('ref missing');
        return;
      }

      clearTimeout(this.timeout);

      this.$refs.notificationContainer.classList.remove('slide-up-animation');
      this.$refs.notificationContainer.classList.add('slide-up-animation');

      this.timeout = setTimeout(() => (
        this.$refs.notificationContainer.classList.remove('slide-up-animation')
      ), 3000);
    },
  },
};
</script>

<style scoped>
  .event-notification-container {
    font-size: 1rem;
    visibility: hidden;
    width: 100%;
  }

  .notification-content {
    margin: 0 auto;
    max-width: 250px;
    color: white;
    background-color: var(--color-cpr-purple);
    font-weight: 700;
    text-align: center;
    padding: .5em;
    box-shadow: .25em .25em .25em rgba(0, 0, 0, 0.3);
    border-radius: .5em;
  }

  .slide-up-animation {
    visibility: initial !important;
    opacity: 0;
    animation: slide-up 3s ease-in-out 1
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    15%, 85% {
      transform: translateY(0%);
      opacity: 100;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
</style>
