<template>
  <div class="row no-wrap event items-center">
    <div class="timestamp">
      {{ new Date(event.timestamp).toLocaleString() }}
    </div>
    <div class="column">
      <div class="event-type">
        {{ covertEventLabel(event.event) }}
      </div>
      <div
        v-if="event.details"
        class="event-details"
      >
        {{ event.details }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EventRow',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    covertEventLabel(eventLabel) {
      return eventLabel.split('-').map(l => l[0].toUpperCase() + l.substring(1) ).join(' ');
    }
  },
}
</script>

<style scoped>
  .timestamp {
    width: 12em;
  }

  .event-details {
    font-size: 0.8em;
  }

  .row {
    padding: 2px 4px;
  }

  .event:nth-child(odd) {
    background-color: whitesmoke;
    border-radius: 3px;
  }
</style>

