<template>
  <div class="header">
    <div class="row justify-between">
      <div class="input-group">
        <label for="name">Patient Name:</label>
        <input
          v-model="patientName"
          type="text"
          name="name"
          id="name"
        >
      </div>
      <div class="input-group">
        <label for="weight">Weight (kg):</label>
        <input
          v-model="patientWeight"
          type="number"
          name="weight"
          id="weight"
          @keypress.enter="removeFocus"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      patientName: '',
      patientWeight: 0,
    };
  },
  watch: {
    patientName: 'updatePatientDetails',
    patientWeight: 'updatePatientDetails',
  },
  methods: {
    updatePatientDetails() {
      const { patientName, patientWeight } = this;
      this.$emit('update-patient-details', { patientName, patientWeight });
    },
    removeFocus($event) {
      $event.target.blur();
    }
  }
};
</script>

<style scoped>
  .header {
    color: white;
  }

  .input-group > input[name=weight] {
    width: 3rem;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
</style>
