<template>
  <div class="column full-height no-wrap">
    <div class="row grow justify-between">
      <button
        class="event-button"
        :class="{ disabled: etApplied }"
        @click="handleETApplied"
      >
        Entubated
      </button>
      <button
        class="event-button"
        :class="{ disabled: ivPlaced }"
        @click="handleIVPlaced"
      >
        IV/IO access
      </button>
      <div class="input-group">
        <label for="ivft">Fluids:</label>
        <input
          v-model="ivft"
          type="text"
          name="ivft"
          id="ivft"
          @keydown.enter="handleFluidTherapyChange"
        >
        <button
          class="event-button ivft-button"
          @click="handleFluidTherapyChange"
        >
          <span class="material-icons">check</span>
        </button>
      </div>
    </div>
    <div class="row margin-top-auto">
      <div class="input-group">
        <label for="vet">Vet:</label>
        <input
          v-model="vetInitials"
          type="text"
          name="vet"
          id="vet"
        >
      </div>
      <div class="input-group">
        <label for="nurse">Nurse:</label>
        <input
          v-model="nurseInitials"
          type="text"
          name="nurse"
          id="nurse"
        >
      </div>
      <DrugsPopup
        :patient-weight="patientDetails.patientWeight"
        class="margin-left-auto"
        @record-event="$emit('record-event', $event)"
      />
    </div>
  </div>
</template>

<script>
import DrugsPopup from '@/components/DrugsPopup';

export default {
  name: 'Footer',
  components: {
    DrugsPopup,
  },
  props: {
    patientDetails: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      vetInitials: '',
      nurseInitials: '',
      etApplied: false,
      ivPlaced: false,
      ivft: '',
    };
  },
  watch: {
    vetInitials: 'updateStaffDetails',
    nurseInitials: 'updateStaffDetails',
  },
  methods: {
    updateStaffDetails() {
      const { vetInitials, nurseInitials } = this;
      this.$emit('update-staff-details', { vetInitials, nurseInitials });
    },
    handleETApplied() {
      if (this.etApplied) return;
      this.etApplied = true;
      this.$emit('record-event', {
        event: 'ET-tube-placed',
      })
    },
    handleIVPlaced() {
      if (this.ivPlaced) return;
      this.ivPlaced = true;
      this.$emit('record-event', {
        event: 'IV-placed',
      })
    },
    handleFluidTherapyChange() {
      this.$emit('record-event', {
        event: 'fluid-therapy-changed',
        details: this.ivft,
      })
    },
  }
}
</script>

<style scoped>
  .input-group {
    color: white;
    height: 2em;
    margin: 2px;
    padding: 2px 2px;
  }

  .input-group > input {
    width: 2em;
    margin-right: 1em;
  }

  .event-button {
    color: white;
    height: 2em;
    padding: 2px 8px;
    margin: 2px;
    border-radius: 3px;
    font-weight: 600;
    background-color: blueviolet;
  }

  .event-button.disabled {
    color: gainsboro;
    background-color: lightslategrey;
  }

  input#ivft {
    width: 7.5em;
    margin-right: 4px;
  }

  .ivft-button {
    margin: 0;
    height: auto;
    padding: 3px 4px;
    font-size: .5em !important;
  }

  .ivft-button .material-icons {
    font-size: .75rem !important;
  }
</style>
